// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionnaireDetails_main__2Tnik {
  width: 80%;
  margin: auto;
}

.QuestionnaireDetails_card__BmlpF {
  width: 100%;
}

.QuestionnaireDetails_formTextLabel__sifZ5 {
  text-align: justify;
  line-height: 1.2rem;
}

.QuestionnaireDetails_formLabel__zQJBR {
  margin: 0 1rem 1rem 0;
  text-align: justify;
  line-height: 1.2rem;
}

.QuestionnaireDetails_badgeContainer__LbdoZ {
  width: 100%;
}

.QuestionnaireDetails_tagMargin__yQzfn {
  padding: 0 0 0 1rem;
  margin-top: 0rem !important;
}

.QuestionnaireDetails_formText__OIpOD {
  margin-bottom: 0.25rem;
}

.QuestionnaireDetails_noHoverPrimary__aguPJ:hover {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: white !important;
}

.QuestionnaireDetails_noHoverOutlinePrimary__0exgL:hover {
  background-color: transparent !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-primary) !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/QuestionnaireDetails/QuestionnaireDetails.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,8CAA8C;EAC9C,0CAA0C;EAC1C,uBAAuB;AACzB;;AAEA;EACE,wCAAwC;EACxC,0CAA0C;EAC1C,mCAAmC;AACrC","sourcesContent":[".main {\r\n  width: 80%;\r\n  margin: auto;\r\n}\r\n\r\n.card {\r\n  width: 100%;\r\n}\r\n\r\n.formTextLabel {\r\n  text-align: justify;\r\n  line-height: 1.2rem;\r\n}\r\n\r\n.formLabel {\r\n  margin: 0 1rem 1rem 0;\r\n  text-align: justify;\r\n  line-height: 1.2rem;\r\n}\r\n\r\n.badgeContainer {\r\n  width: 100%;\r\n}\r\n\r\n.tagMargin {\r\n  padding: 0 0 0 1rem;\r\n  margin-top: 0rem !important;\r\n}\r\n\r\n.formText {\r\n  margin-bottom: 0.25rem;\r\n}\r\n\r\n.noHoverPrimary:hover {\r\n  background-color: var(--bs-primary) !important;\r\n  border-color: var(--bs-primary) !important;\r\n  color: white !important;\r\n}\r\n\r\n.noHoverOutlinePrimary:hover {\r\n  background-color: transparent !important;\r\n  border-color: var(--bs-primary) !important;\r\n  color: var(--bs-primary) !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `QuestionnaireDetails_main__2Tnik`,
	"card": `QuestionnaireDetails_card__BmlpF`,
	"formTextLabel": `QuestionnaireDetails_formTextLabel__sifZ5`,
	"formLabel": `QuestionnaireDetails_formLabel__zQJBR`,
	"badgeContainer": `QuestionnaireDetails_badgeContainer__LbdoZ`,
	"tagMargin": `QuestionnaireDetails_tagMargin__yQzfn`,
	"formText": `QuestionnaireDetails_formText__OIpOD`,
	"noHoverPrimary": `QuestionnaireDetails_noHoverPrimary__aguPJ`,
	"noHoverOutlinePrimary": `QuestionnaireDetails_noHoverOutlinePrimary__0exgL`
};
export default ___CSS_LOADER_EXPORT___;
