// React
import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
// Components
import JanusTestablePage from "../../components/JanusTestablePage/JanusTestablePage";
import CodeEditor from "../../components/CodeEditor/CodeEditor";
import Output from "../../components/CodeEditor/Output";
import LoadingButton from "../../components/LoadingButton/LoadingButton";
import { ToastQueueProvider } from "../../components/ToastQueueProvider/ToastQueueProvider";
import TooltipCustom from "../../components/TooltipWrapper/TooltipWrapper";
// Fhir Front library
import { FhirStatus, StatusTag, Title } from "@fyrstain/fhir-front-library";
// FHIR
import Client from "fhir-kit-client";
// Resources
import {
  Questionnaire,
  Parameters,
  QuestionnaireResponse,
  Bundle,
} from "fhir/r5";
// Translation
import i18n from "i18next";
// React Bootstrap
import { Button, Card, Form } from "react-bootstrap";
// Navigation
import { useNavigate, useParams } from "react-router-dom";
// Styles
import styles from "./QuestionnaireDetails.module.css";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faRotateRight,
  faPlay,
  faGear,
  faEye,
  faEyeSlash,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";

////////////////////////////////
//         Interfaces         //
////////////////////////////////

interface inputs {
  name: string;
  type: string;
  mode?: string;
  content?: string;
  profile?: string;
}

const QuestionnaireDetail: FunctionComponent = () => {
    
  /////////////////////////////////////
  //      Constants / ValueSet       //
  /////////////////////////////////////

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Questionnaire informations
  const { questionnaireId } = useParams();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");

  // Contents & Resources
  const [questionnaireContent, setQuestionnaireContent] = useState("");
  const [questionnaireResource, setQuestionnaireResource] = useState(
    {} as Questionnaire
  );
  const [initialQuestionnaireContent, setInitialQuestionnaireContent] =
    useState("");
  const [questionnaireResponseContent, setQuestionnaireResponseContent] =
    useState("");
  const [outputContent, setOutputContent] = useState(new Array<inputs>());

  // To enable or disabled the "play" button to que QuestionnaireResponse field when the user has used the populate operation
  const [isPopulated, setIsPopulated] = useState(true);

  // Show the spinner when "true" and hide when "false"
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [isPopulateLoading, setIsPopulateLoading] = useState(false);
  const [isExtractLoading, setIsExtractLoading] = useState(false);

  // To disable the "check" button to the Output field when the user has an error
  const [isFailingOperation, setIsfailingOperation] = useState(false);

  // To show or hide the content/card with the buttonToggle
  const [showQuestionnaire, setShowQuestionnaire] = useState(true);
  const [showQuestionnaireResponse, setShowQuestionnaireResponse] =
    useState(true);
  const [showOutput, setShowOutput] = useState(true);

  // To resize the cards
  const [questionnaireFlex, setQuestionnaireFlex] = useState({ flex: 1 });
  const [questionnaireResponseFlex, setQuestionnaireResponseFlex] = useState({
    flex: 1,
  });
  const [outputFlex, setOutputFlex] = useState({ flex: 1 });
  const componentWidthRef = useRef<HTMLDivElement | null>(null);
  const questionnaireRef = useRef<HTMLDivElement | null>(null);
  const questionnaireResponseRef = useRef<HTMLDivElement | null>(null);
  const outputRef = useRef<HTMLDivElement | null>(null);

  // Creating a Toast using the Component Toast
  const { createToast } = ToastQueueProvider.useToastQueue();

  /////////////////////////////////////
  //             Client              //
  /////////////////////////////////////

  const fhirClient = new Client({
    baseUrl: process.env.REACT_APP_FHIR_URL ?? "fhir",
  });

  const fhirOperationClient = new Client({
    baseUrl: process.env.REACT_APP_QUESTIONNAIRE_URL ?? "fhir",
  });

  //////////////////////////////
  //           Error          //
  //////////////////////////////

  const onError = useCallback(() => {
    navigate("/Error");
  }, [navigate]);

  ////////////////////////////////
  //           Actions          //
  ////////////////////////////////

  useEffect(() => {
    load();
  }, []);

  /**
   * To load the Questionnaire and switch the state of setLoading
   */
  async function load() {
    setLoading(true);
    await loadQuestionnaire();
    setLoading(false);
  }

  /**
   * Load Questionnaire from the back to populate the fields.
   *
   * @returns the promise of a Questionnaire.
   */
  async function loadQuestionnaire() {
    try {
      const response = await fhirClient.read({
        resourceType: "Questionnaire",
        id: questionnaireId ?? "",
      });
      if (response.resourceType !== "Questionnaire") {
        throw Error(response.statusText);
      }
      const questionnaire: Questionnaire = response as Questionnaire;
      const stringifiedQuestionnaire = JSON.stringify(
        questionnaire,
        null,
        "\t"
      );
      setName(questionnaire.title ? questionnaire.title : "N/A");
      setDescription(
        questionnaire.description ? questionnaire.description : "N/A"
      );
      setStatus(questionnaire.status ? questionnaire.status : "N/A");
      setQuestionnaireContent(stringifiedQuestionnaire);
      setQuestionnaireResource(questionnaire);
      setInitialQuestionnaireContent(stringifiedQuestionnaire);
    } catch (error) {
      onError();
    }
  }

  //Functions to show or hide the cards with a ToggleButton
  function toggleQuestionnaire() {
    setShowQuestionnaire(!showQuestionnaire);
  }
  function toggleQuestionnaireResponse() {
    setShowQuestionnaireResponse(!showQuestionnaireResponse);
  }
  function toggleOutput() {
    setShowOutput(!showOutput);
  }

  /**
   * Function to adjust size/flex according to toggle buttons
   */
  const adjustCardSizes = () => {
    const visibleCards = [
      showQuestionnaire,
      showQuestionnaireResponse,
      showOutput,
    ].filter(Boolean).length;
    // To have the same size for each card when all are visible or adjust the size when one or two are hidden
    const equalFlex = 1 / visibleCards;
    setQuestionnaireFlex({ flex: showQuestionnaire ? equalFlex : 0 });
    setQuestionnaireResponseFlex({
      flex: showQuestionnaireResponse ? equalFlex : 0,
    });
    setOutputFlex({ flex: showOutput ? equalFlex : 0 });
  };

  /**
   * To ajust the size/flex cards
   */
  useEffect(() => {
    adjustCardSizes();
  }, [showQuestionnaire, showQuestionnaireResponse, showOutput]);

  /**
   * Function to resize the components using a resizer bar
   * It calculates the new size, checking the minimum size and adjusts the flex-glow values
   * @param leftRef is the current size of the left component
   * @param rightRef is the current size of the right component
   * @param setSizeLeft can update the flew-grow value of the left component
   * @param setSizeRight can update the flew-grow value of the right component
   * @returns a function that is triggered by the `onMouseDown` event to start the resizing process
   */
  const handleResize =
    (
      leftRef: MutableRefObject<HTMLDivElement | null>,
      rightRef: MutableRefObject<HTMLDivElement | null>,
      setSizeLeft: (newSize: { flex: number }) => void,
      setSizeRight: (newSize: { flex: number }) => void
    ) =>
    (mouseDownEvent: React.MouseEvent) => {
      // To stop the selection during the resizing
      document.body.classList.add("noSelectWhenResizing");
      // Get the current size of the components
      let leftSize = leftRef.current?.offsetWidth ?? 0;
      let rightSize = rightRef.current?.offsetWidth ?? 0;
      // Get the total size of the components
      const sizeSum = +leftSize + +rightSize;
      // Get the current flex-grow value of the components
      const leftFlex = leftRef.current?.style.flexGrow ?? 1;
      const rightFlex = rightRef.current?.style.flexGrow ?? 1;
      // Get the total flex-grow value of the components
      const flexSum: number = Number(leftFlex) + Number(rightFlex);
      // Get the initial position of the mouse
      const startPosition = { width: mouseDownEvent.pageX };

      function onMouseMove(mouseMoveEvent: MouseEvent) {
        // Get the current position of the mouse
        let currentPosition = mouseMoveEvent.pageX;
        // Calculate the distance between the initial and current position
        let distance = startPosition.width - currentPosition;
        // Update the new size of the components
        let newLeftSize = leftSize - distance;
        let newRightSize = rightSize + distance;
        // Set the minimum size of the components
        const MIN_SIZE = 0.22 * (componentWidthRef.current?.offsetWidth ?? 0);
        // Check if the new size is less than the minimum size
        if (newLeftSize <= MIN_SIZE) {
          newRightSize += newLeftSize - MIN_SIZE;
          newLeftSize = MIN_SIZE;
        }
        if (newRightSize <= MIN_SIZE) {
          newLeftSize += newRightSize - MIN_SIZE;
          newRightSize = MIN_SIZE;
        }
        // Update the flex-grow value of the components
        let leftFlexNew = flexSum * (newLeftSize / sizeSum);
        let rightFlexNew = flexSum * (newRightSize / sizeSum);
        // Update the size of the components
        setSizeLeft({
          flex: leftFlexNew,
        });
        setSizeRight({
          flex: rightFlexNew,
        });
      }
      function onMouseUp() {
        document.body.removeEventListener("mousemove", onMouseMove);
        document.body.removeEventListener("mouseup", onMouseUp);
        // The selection is possible again
        document.body.classList.remove("noSelectWhenResizing");
      }
      document.body.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("mouseup", onMouseUp, { once: true });
    };

  /**
   * Function to stringify the response of the questionnaire in JSON
   * @param response Response of the questionnaire
   * @returns a stringify response
   */
  function parseResponse(response: QuestionnaireResponse) {
    setQuestionnaireResponseContent(JSON.stringify(response, null, "\t"));
  }

  /**
   * Function to stringify the response of the extract in JSON
   * @param response Response of the extract
   * @returns a stringify response
   */
  function parseExtractResponse(response: Bundle) {
    let inputs = new Array<inputs>();
    response.entry?.map((entry) => {
      inputs.push({
        name: entry.resource?.id ? entry.resource?.id : "",
        type: entry.resource?.resourceType ? entry.resource?.resourceType : "",
        content: JSON.stringify(entry.resource, null, "\t"),
      });
    });
    // Update the state with the formated data
    setOutputContent(inputs);
  }

  function getQuestionnaireAsResource(): Questionnaire {
    return JSON.parse(questionnaireContent) as Questionnaire;
  }

  function getQuestionnaireResponseAsResource(): QuestionnaireResponse {
    return JSON.parse(questionnaireResponseContent) as QuestionnaireResponse;
  }

  /**
   * Save the content of the questionnaire
   */
  async function onSave() {
    setIsSaveLoading(true);
    try {
      await fhirClient.update({
        resourceType: "Questionnaire",
        id: questionnaireId ?? "",
        body: JSON.parse(questionnaireContent),
      });
    } catch (error) {
      onError();
      setIsSaveLoading(false);
    }
    setIsSaveLoading(false);
  }

  /**
   * Reset the Questionnaire's content, returning the initial content
   */
  const resetQuestionnaireContent = async () => {
    setIsResetLoading(true);
    //setTimeout is used to show the loading spinner
    setTimeout(() => {
      setQuestionnaireContent(initialQuestionnaireContent);
      setIsResetLoading(false);
    }, 200);
  };

  /**
   * Function to use the operation Populate for a questionnaire
   */
  async function onPopulate() {
    setIsPopulateLoading(true);
    // Use the parameter questionnaire
    let parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "questionnaire",
          resource: getQuestionnaireAsResource(),
        },
      ],
    };
    await fhirOperationClient
      .operation({
        name: "populate",
        input: parameter,
        resourceType: "Questionnaire",
      })
      .then((response) => {
        parseResponse(response);
        setIsPopulated(false);
        setIsfailingOperation(false);
      })
      .catch((error) => {
        setIsPopulateLoading(false);
        setIsfailingOperation(true);
        // See errors in the code editor (Output)
        setOutputContent([
          {
            name: "error",
            type: "Error",
            content: error.response?.data
              ? JSON.stringify(error.response?.data, null, "\t")
              : error.toString(),
          },
        ]);
        // A toast when an error occured
        createToast({
          title: i18n.t("text.errorvalidateheader"),
          icon: faTriangleExclamation,
          body: error.response
            ? JSON.stringify(
                error.response.data.issue[0].diagnostics,
                null,
                "\t"
              )
            : i18n.t("text.errorvalidatetext"),
        });
      });
    setIsPopulateLoading(false);
  }

  /**
   * Function using the operation Extract to extract the data from a questionnaire response
   */
  async function onExtract() {
    setIsExtractLoading(true);
    // Use the parameter questionnaire-response
    let parameter: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "questionnaire-response",
          resource: getQuestionnaireResponseAsResource(),
        },
      ],
    };
    await fhirOperationClient
      .operation({
        name: "extract",
        input: parameter,
        resourceType: "QuestionnaireResponse",
      })
      .then((response) => {
        parseExtractResponse(response);
        setIsfailingOperation(false);
      })
      .catch((error) => {
        setIsExtractLoading(false);
        setIsfailingOperation(true);
        // See errors in the Output field
        setOutputContent([
          {
            name: "error",
            type: "Error",
            content: error.response?.data
              ? JSON.stringify(error.response?.data, null, "\t")
              : error.toString(),
          },
        ]);
        // A toast when an error occured
        createToast({
          title: i18n.t("text.errorvalidateheader"),
          icon: faTriangleExclamation,
          body: error.response
            ? JSON.stringify(
                error.response.data.issue[0].diagnostics,
                null,
                "\t"
              )
            : i18n.t("text.errorvalidatetext"),
        });
      });
    setIsExtractLoading(false);
  }

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <JanusTestablePage
      titleKey="Questionnaire"
      loading={loading}
      needsLogin={true}
      urlTestable={questionnaireResource.url}
      testUrl={process.env.REACT_APP_TEST_URL}
      testServerUrl={process.env.REACT_APP_TEST_SERVER_URL}
      serverId={process.env.REACT_APP_R4_SERVER_ID}
      clientId={process.env.REACT_APP_R4_CLIENT_ID}
    >
      <>
        {/* INFORMATION */}
        <div className="testAccordionSection">
          <div className="section testAccordionSection">
            <Card className={styles.card}>
              <Card.Header>
                <Title level={2} content="Informations" />
              </Card.Header>

              <Card.Body className="flexWrap">
                <div className={styles.form}>
                  <div className={styles.formTextLabel}>
                    <Form.Label className={styles.formLabel}>
                      <strong className={styles.label}>ID :</strong>
                    </Form.Label>
                    <Form.Text>{questionnaireId}</Form.Text>
                  </div>

                  <div className={styles.formTextLabel}>
                    <Form.Label className={styles.formLabel}>
                      <strong className={styles.label}>
                        {i18n.t("label.name")} :
                      </strong>
                    </Form.Label>
                    <Form.Text>{name}</Form.Text>
                  </div>

                  <div
                    className={[styles.badgeContainer, "flexWrap"].join(" ")}
                  >
                    <Form.Label className={styles.statuslabel}>
                      <strong>{i18n.t("label.status")} :</strong>
                    </Form.Label>
                    <Form.Text
                      className={[styles.formText, styles.tagMargin].join(" ")}
                    >
                      <StatusTag
                        status={FhirStatus[status as keyof typeof FhirStatus]}
                        statusMessage={status}
                      />
                    </Form.Text>
                  </div>

                  <div className={styles.formTextLabel}>
                    <Form.Label className={styles.formLabel}>
                      <strong className={styles.label}>
                        {i18n.t("label.generaldescription")} :
                      </strong>
                    </Form.Label>
                    <Form.Text>{description}</Form.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="section">
            {/* BUTTONS TOGGLE - SHOW OR HIDE CARDS*/}
            <div className="d-flex mb-3 justify-content-center">
              <Button
                variant={showQuestionnaire ? "primary" : "outline-primary"}
                className={`${"toggleButton"} ${
                  showQuestionnaire
                    ? styles.noHoverPrimary
                    : styles.noHoverOutlinePrimary
                }`}
                onClick={toggleQuestionnaire}
              >
                <FontAwesomeIcon
                  icon={showQuestionnaire ? faEye : faEyeSlash}
                  className="me-3"
                />
                Questionnaire
              </Button>
              <Button
                variant={
                  showQuestionnaireResponse ? "primary" : "outline-primary"
                }
                className={`${"me-3 ms-3 toggleButton"} ${
                  showQuestionnaireResponse
                    ? styles.noHoverPrimary
                    : styles.noHoverOutlinePrimary
                }`}
                onClick={toggleQuestionnaireResponse}
              >
                <FontAwesomeIcon
                  icon={showQuestionnaireResponse ? faEye : faEyeSlash}
                  className="me-3"
                />
                {i18n.t("text.questionnaireresponse")}
              </Button>
              <Button
                variant={showOutput ? "primary" : "outline-primary"}
                className={`${"toggleButton"} ${
                  showOutput
                    ? styles.noHoverPrimary
                    : styles.noHoverOutlinePrimary
                }`}
                onClick={toggleOutput}
              >
                <FontAwesomeIcon
                  icon={showOutput ? faEye : faEyeSlash}
                  className="me-3"
                />
                Outputs
              </Button>
            </div>

            <div className="resizableContainer" ref={componentWidthRef}>
              {/* Questionnaire  - Questionnaire ENGINE */}
              {/* Questionnaire */}
              {showQuestionnaire && (
                <div
                  className="resizableComponent"
                  style={{
                    flex: questionnaireFlex.flex + " 0 0%",
                  }}
                  ref={questionnaireRef}
                >
                  <Card className={styles.card}>
                    <Card.Header className="cardNoWrap">
                      <Title
                        level={2}
                        content="Questionnaire"
                        className="textTruncate"
                      />
                      <div className="buttonHeaderCard">
                        <LoadingButton
                          isLoading={isSaveLoading}
                          icon={faSave}
                          onClick={onSave}
                          enabledTooltipText={i18n.t("tooltip.save")}
                        />
                        <TooltipCustom
                          id="tooltipActualizeButton"
                          text={i18n.t("tooltip.disabled")}
                        >
                          <FontAwesomeIcon
                            icon={faGear}
                            size="2x"
                            className="buttonIconDisabled"
                          />
                        </TooltipCustom>
                        <LoadingButton
                          isLoading={isResetLoading}
                          icon={faRotateRight}
                          onClick={resetQuestionnaireContent}
                          enabledTooltipText={i18n.t("tooltip.reset")}
                        />
                        <LoadingButton
                          isLoading={isPopulateLoading}
                          icon={faPlay}
                          onClick={onPopulate}
                          enabledTooltipText={i18n.t("tooltip.play")}
                          operationName="$Populate"
                        />
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <CodeEditor
                        content={questionnaireContent}
                        setContent={setQuestionnaireContent}
                      />
                    </Card.Body>
                  </Card>
                  {(showQuestionnaireResponse || showOutput) && (
                    <div
                      className="resizerBar"
                      onMouseDown={handleResize(
                        questionnaireRef,
                        showQuestionnaireResponse
                          ? questionnaireResponseRef
                          : outputRef,
                        setQuestionnaireFlex,
                        showQuestionnaireResponse
                          ? setQuestionnaireResponseFlex
                          : setOutputFlex
                      )}
                    ></div>
                  )}
                </div>
              )}

              {/* QuestionnaireResponse */}
              {showQuestionnaireResponse && (
                <div
                  className="resizableComponent"
                  style={{
                    flex: questionnaireResponseFlex.flex + " 0 0%",
                  }}
                  ref={questionnaireResponseRef}
                >
                  <Card className={styles.card}>
                    <Card.Header className="cardNoWrap">
                      <Title
                        level={2}
                        content={i18n.t("text.questionnaireresponse")}
                        className="textTruncate"
                      />
                      <div className="buttonHeaderCard">
                        <LoadingButton
                          isLoading={isExtractLoading}
                          enabledTooltipText={i18n.t("tooltip.play")}
                          icon={faPlay}
                          onClick={onExtract}
                          isDisabled={isPopulated}
                          disabledTooltipText={i18n.t(
                            "tooltip.disabledplayquestionnaire"
                          )}
                          operationName="$Extract"
                        />
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <CodeEditor
                        content={questionnaireResponseContent}
                        setContent={(value: any) =>
                          setQuestionnaireResponseContent(value)
                        }
                      />
                    </Card.Body>
                  </Card>
                  {showOutput && (
                    <div
                      className="resizerBar"
                      onMouseDown={handleResize(
                        questionnaireResponseRef,
                        outputRef,
                        setQuestionnaireResponseFlex,
                        setOutputFlex
                      )}
                    ></div>
                  )}
                </div>
              )}
              {/* Extraction - Output */}
              {showOutput && (
                <div
                  className="resizableComponent"
                  ref={outputRef}
                  style={{
                    flex: outputFlex.flex + " 0 0%",
                  }}
                >
                  <Output
                    content={outputContent}
                    setContent={setOutputContent}
                    isFailingOperation={isFailingOperation}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </JanusTestablePage>
  );
};

export default QuestionnaireDetail;
