// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CodeEditor_main__Luqa0 {
  width: 80%;
  margin: auto;
}

.CodeEditor_card__uvYKo {
  width: 100%;
}

.CodeEditor_textAreaOutput__XBPbz {
  resize: none;
  height: 75vh;
}

.CodeEditor_cardOutputPadding__WH5Uz {
  padding: 0 !important;
}

.CodeEditor_link__jMnWG:hover {
  color: var(--info-color);
  text-decoration: underline;
  cursor: pointer;
}

.CodeEditor_alertTitle__q3IzN {
  margin-left: 1rem;
}

.CodeEditor_alertContainer__7Twyd {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/CodeEditor/CodeEditor.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;EACxB,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".main {\r\n  width: 80%;\r\n  margin: auto;\r\n}\r\n\r\n.card {\r\n  width: 100%;\r\n}\r\n\r\n.textAreaOutput {\r\n  resize: none;\r\n  height: 75vh;\r\n}\r\n\r\n.cardOutputPadding {\r\n  padding: 0 !important;\r\n}\r\n\r\n.link:hover {\r\n  color: var(--info-color);\r\n  text-decoration: underline;\r\n  cursor: pointer;\r\n}\r\n\r\n.alertTitle {\r\n  margin-left: 1rem;\r\n}\r\n\r\n.alertContainer {\r\n  display: flex;\r\n  justify-content: space-between;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `CodeEditor_main__Luqa0`,
	"card": `CodeEditor_card__uvYKo`,
	"textAreaOutput": `CodeEditor_textAreaOutput__XBPbz`,
	"cardOutputPadding": `CodeEditor_cardOutputPadding__WH5Uz`,
	"link": `CodeEditor_link__jMnWG`,
	"alertTitle": `CodeEditor_alertTitle__q3IzN`,
	"alertContainer": `CodeEditor_alertContainer__7Twyd`
};
export default ___CSS_LOADER_EXPORT___;
