// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StructureMapDetails_main__yrnjD {
  width: 80%;
  margin: auto;
}

.StructureMapDetails_card__wsSge {
  width: 100%;
}

.StructureMapDetails_formTextLabel__sJLxQ {
  text-align: justify;
  line-height: 1.2rem;
}

.StructureMapDetails_formLabel__hbDQi {
  margin: 0 1rem 1rem 0;
  text-align: justify;
  line-height: 1.2rem;
}

.StructureMapDetails_badgeContainer__TMk6S {
  width: 100%;
}

.StructureMapDetails_tagMargin__M79xx {
  padding: 0 0 0 1rem;
  margin-top: 0rem !important;
}

.StructureMapDetails_formText__6kGyX {
  margin-bottom: 0.25rem;
}

.StructureMapDetails_scrollable__3\\+2Ez {
  max-height: 85vh;
  overflow: scroll;
}

.StructureMapDetails_noHoverPrimary__ppVYp:hover {
  background-color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  color: white !important;
}

.StructureMapDetails_noHoverOutlinePrimary__K60OU:hover {
  background-color: transparent !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-primary) !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/StructureMapDetails/StructureMapDetails.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,8CAA8C;EAC9C,0CAA0C;EAC1C,uBAAuB;AACzB;;AAEA;EACE,wCAAwC;EACxC,0CAA0C;EAC1C,mCAAmC;AACrC","sourcesContent":[".main {\r\n  width: 80%;\r\n  margin: auto;\r\n}\r\n\r\n.card {\r\n  width: 100%;\r\n}\r\n\r\n.formTextLabel {\r\n  text-align: justify;\r\n  line-height: 1.2rem;\r\n}\r\n\r\n.formLabel {\r\n  margin: 0 1rem 1rem 0;\r\n  text-align: justify;\r\n  line-height: 1.2rem;\r\n}\r\n\r\n.badgeContainer {\r\n  width: 100%;\r\n}\r\n\r\n.tagMargin {\r\n  padding: 0 0 0 1rem;\r\n  margin-top: 0rem !important;\r\n}\r\n\r\n.formText {\r\n  margin-bottom: 0.25rem;\r\n}\r\n\r\n.scrollable {\r\n  max-height: 85vh;\r\n  overflow: scroll;\r\n}\r\n\r\n.noHoverPrimary:hover {\r\n  background-color: var(--bs-primary) !important;\r\n  border-color: var(--bs-primary) !important;\r\n  color: white !important;\r\n}\r\n\r\n.noHoverOutlinePrimary:hover {\r\n  background-color: transparent !important;\r\n  border-color: var(--bs-primary) !important;\r\n  color: var(--bs-primary) !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `StructureMapDetails_main__yrnjD`,
	"card": `StructureMapDetails_card__wsSge`,
	"formTextLabel": `StructureMapDetails_formTextLabel__sJLxQ`,
	"formLabel": `StructureMapDetails_formLabel__hbDQi`,
	"badgeContainer": `StructureMapDetails_badgeContainer__TMk6S`,
	"tagMargin": `StructureMapDetails_tagMargin__M79xx`,
	"formText": `StructureMapDetails_formText__6kGyX`,
	"scrollable": `StructureMapDetails_scrollable__3+2Ez`,
	"noHoverPrimary": `StructureMapDetails_noHoverPrimary__ppVYp`,
	"noHoverOutlinePrimary": `StructureMapDetails_noHoverOutlinePrimary__K60OU`
};
export default ___CSS_LOADER_EXPORT___;
