// React
import {
  FunctionComponent,
  JSXElementConstructor,
  ReactElement,
  useCallback,
} from "react";
// Components
import {
  TestablePage,
  TestablePageConfiguration,
} from "@fyrstain/fhir-front-library";
import { MenuItem } from "@fyrstain/fhir-front-library";
// Translation
import i18n from "i18next";
// Authentication
import UserService from "../../services/UserService";
// Navigation
import { useNavigate } from "react-router-dom";

const JanusTestablePage: FunctionComponent<{
  // The title of the page
  titleKey?: string;
  // The loading state of the page
  loading?: boolean;
  // The content of the page
  children?: ReactElement<any, string | JSXElementConstructor<any>> | undefined;
  // Fit the footer to the bottom of the page
  fitFooter?: boolean;
  // If the page needs login or not
  needsLogin: boolean;
  // Props for the Testable Page
  urlTestable: string | undefined;
  testUrl: string | undefined;
  testServerUrl: string | undefined;
  serverId: string | undefined;
  clientId: string | undefined;
}> = (props) => {
    
  /////////////////////////////////
  //        NAVIGATION           //
  /////////////////////////////////

  const navigate = useNavigate();

  /////////////////////////////////
  //           METHODS           //
  /////////////////////////////////

  /*
   **
   **  This function is used to handle the login of the user.
   **  If the user is not logged in and the menu item needs login, it will redirect to the login page.
   **
   */
  const handleLogin = useCallback(
    (config: MenuItem) => {
      if (config.needsLogin) {
        if (!UserService.isAuthenticated()) {
          UserService.doLogin();
          navigate("/login");
        }
      }
    },
    [navigate]
  );

  /**
   * This function is used to manage translation changes 
   * @param event when the user select an option
   */
  const handleLangChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    i18n.changeLanguage(event.target.value);
  };

  /**
   * Configuration of the testable page 
   */
  const fullConfig: TestablePageConfiguration = {
    // Translation
    language: i18n.t,
    navigationBarConfigs: {
      // Application logo
      homeLink: "/Home",
      logoLink: "/assets/Januslogo.png",
      logoWidth: "7.5rem",
      alt: "Janus Logo",
      // Authentication
      authentication: {
        handleLogin: handleLogin,
        token: UserService.getKC().token,
        doLogin: UserService.doLogin,
        doLogout: UserService.doLogout,
        isAuthenticated: () => UserService.isAuthenticated() || false,
        getUserName: () => UserService.getUsername(),
      },
      // the menu items with their subItems who contains the navigation to the differents pages
      menuItems: [
        {
          title: "STRUCTURE MAPS",
          link: "/StructureMaps",
          needsLogin: false,
          // subItems: [
          //     {
          //         title: '',
          //         link: '/'
          //     }
          // ]
        },
        {
          title: "QUESTIONNAIRES",
          link: "/Questionnaires",
          needsLogin: false,
          // subItems: [
          //     {
          //         title: '',
          //         link: '/'
          //     }
          // ]
        },
      ],
      // the user items
      // Admin, Login, Logout are the default items
      dropDownItems: [],
      // customItems: <ServerUrlField />
    },
    // the title of the page
    titleKey: props.titleKey,
    // the loading state of the page
    loading: props.loading,
    // the content of the page
    children: props.children,
    // the footer of the page
    fitFooter: props.fitFooter,
    // if the page needs login or not
    needsLogin: props.needsLogin,
    // props for the testable page
    url: props.urlTestable,
    testUrl: props.testUrl,
    testServerUrl: props.testServerUrl,
    serverId: props.serverId,
    clientId: props.clientId,
    // The footer configuration
    footerConfigs: {
      languages: {
        default: i18n.language,
        onChange: handleLangChange,
        options: [
          {
            label: "English",
            value: "en",
          },
          {
            label: "Français",
            value: "fr",
          },
        ],
      },
      logo: [
        {
          src: "https://fyrstain.com/wp-content/uploads/2022/10/Logo_fyrstain_horyzontal.svg",
          alt: "Horizontal logo type",
          href: "/Home",
        },
        {
          src: "/assets/logo-OVH.svg",
          alt: "Logo OVH",
        },
        ...(process.env.REACT_APP_DISPLAY_CLIENT_LOGO === "true"
          ? [
              {
                src: "https://fyrstain.com/wp-content/uploads/2022/10/Logo_fyrstain_horyzontal.svg",
                alt: "Horizontal logo type",
                href: "/Home",
              },
              {
                src: "/assets/client_logo.jpg",
                alt: "HL7 Europe logo",
              },
            ]
          : []),
      ],
      items: [
        {
          label: i18n.t("footer.items.about"),
          href: "/InProgress",
        },
        {
          label: i18n.t("footer.items.contact"),
          href: "/InProgress",
        },
        {
          label: i18n.t("footer.items.problemtracking"),
          href: "/InProgress",
        },
      ],
    },
  };

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return <TestablePage {...fullConfig} />;
};

export default JanusTestablePage;
