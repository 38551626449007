// React
import React, { useRef } from "react";
// Monaco (Editor)
import Editor from "@monaco-editor/react";
// Translation
import i18n from "i18next";

////////////////////////////////
//           Props            //
////////////////////////////////

interface CodeEditorProps {
  content: string;
  setContent: any;
}

const CodeEditor: React.FC<CodeEditorProps> = ({ content, setContent }) => {
    
  /////////////////////////////////////
  //      Constants / ValueSet       //
  /////////////////////////////////////

  const editorRef = useRef();

  ////////////////////////////////
  //           Actions          //
  ////////////////////////////////

  const onMount = (editor: any) => {
    editorRef.current = editor;
  };

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <>
      <Editor
        height={"75vh"}
        theme="vs-light"
        defaultLanguage="json"
        defaultValue={i18n.t("text.editortext")}
        value={content}
        onMount={onMount}
        onChange={(value: any) => setContent(value)}
        options={{
          automaticLayout: true,
          // Disable autocomplete
          quickSuggestions: false,
          // Disable suggestions on trigger characters
          suggestOnTriggerCharacters: false,
          // Disable minimap
          minimap: {
            enabled: false,
          },
        }}
      />
    </>
  );
};

export default CodeEditor;
